import { useCallback, useContext } from "react"
import { SimpleMap } from "../utils"
import { AppContext, FallbackRefCode } from "../constants"

const cleanUndefined = (args: any = {}) => {
  for (const key in args) {
    if (typeof args[key] === 'undefined') {
      delete args[key] // eslint-disable-line no-param-reassign
    }
  }

  return args
}

export enum EvtCategory {
  PageView = "page_view",
  Event = "event",
}

export enum EvtAction {
  ClickInstallBanner = "click_install_banner",
  ClickLogin = "click_login",
  ClickRegister = "click_register",

  InstallSuccess = "install_success",

  ShowOpenExtBrowser = "show_open_ext_browser",
  ShowIosAddToHome = "show_ios_add_to_home",
}

const singularEventTracker: SimpleMap = {};

const useGtmTracker = () => {
  const appContext = useContext(AppContext);

  const sendEvent = useCallback((eventAction: EvtAction) => {
    const { isFbIab, isFullScreen, isIosDevice, isLineIab, urls } = appContext;
    const refCode = urls?.refCode ?? FallbackRefCode;
    const data = cleanUndefined({
      event_category: EvtCategory.Event,
      event: eventAction,
      event_label: refCode,

      // custom args
      isFbIab, isFullScreen, isIosDevice, isLineIab,
      refCode,
    })

    if (typeof window !== 'undefined') {
      if ("logGoogleAnalytics" in window && typeof window.logGoogleAnalytics === "function")
        window.logGoogleAnalytics?.(data);
      if ("dataLayer" in window && Array.isArray(window.dataLayer))
        window.dataLayer?.push(data)
    }
  }, [appContext]);

  const sendSingularEvent = useCallback((eventAction: EvtAction) => {
    if (singularEventTracker[eventAction]) return;

    sendEvent(eventAction);
  }, [sendEvent]);

  return { sendEvent, sendSingularEvent };
}

export default useGtmTracker;
