import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const DEFAULT_URL = "";

export const LOGIN_URL = "https://a.vtrk8.com/28e7c388-c2b5-4891-8f8b-d878f8e04bf3";
export const REGISTER_URL = "https://a.vtrk8.com/9db3e42e-5432-464a-b75d-c17609133daa";

export const LOGIN_URL_MAP = {};

export const REGISTER_URL_MAP = {};

export const LineSupportLink = "https://go989thai.com/cs";

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export * from "./codes";
export * from "./context";
