import { Box, Container, ContainerProps } from "@mui/material";
import React from "react";
import { Bg } from "src/assets";
import { createStyles } from "src/main/utils";

interface ContentProps extends React.PropsWithChildren, ContainerProps {}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...containerProps } = props;

  return (
    <Box sx={styles.root}>
      <Container
        {...containerProps}
        sx={styles.container}
        maxWidth="sm"
      >
        {children}
      </Container>
    </Box>
  );
};

const styles = createStyles({
  root: {
    minHeight: "100vh",
  },
  container: {
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    backgroundPositionX: "center",
    // backgroundPositionY: "40px",
    backgroundColor: "#2E005C",

    height: "100%",
    padding: "0 !important",
  },
});

export default Content;
