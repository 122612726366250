import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack, Typography } from "@mui/material";
import React, { MouseEvent, useCallback, useContext } from "react";
import {
  BgSection,
  BtnDownloadAppstore,
  BtnDownloadGoogleplay,
  HeroBackground,
  HeroForeground,
  HeroMidground,
  LoginButtonImage,
  Logo,
  LogoHero,
  Payments,
  Providers,
  RegisterButtonImage,
} from "src/assets";
import LineSupportButton from "src/main/components/LineSupportButton";
import { AppContext } from "src/main/constants";
import useGtmTracker, { EvtAction } from "src/main/hooks/useGtmTracker";
import { createStyles, isMobile, joinSx } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const { sendEvent } = useGtmTracker();
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  const handleClick = useCallback(
    (type: "login" | "register") => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (type === "login") {
        sendEvent(EvtAction.ClickLogin);
        window.open(loginUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      } else {
        sendEvent(EvtAction.ClickRegister);
        window.open(registerUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      }
    },
    [loginUrl, registerUrl, sendEvent],
  );

  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">Go989</VisuallyHidden>
      <Typography sx={joinSx(styles.hightlight, styles.title)}>สล็อตออนไลน์</Typography>
      <Typography sx={joinSx(styles.hightlight, styles.title)}>ลิขสิทธิ์แท้</Typography>

      <img
        alt="logo"
        src={LogoHero}
        width={360}
      />

      <Box mt="-32px">
        <Typography sx={styles.title2}>
          <span
            style={{
              color: "#F3C148",
              fontSize: "32px",
              fontWeight: 800,
            }}
          >
            %
          </span>{" "}
          ชนะเยอะกว่าที่อื่นชัวร์!
        </Typography>
        <Typography sx={styles.title2}>ฝาก/ถอน ทันใจ บริการ 24/7</Typography>
      </Box>

      <Box sx={styles.buttons}>
        <a
          href={loginUrl}
          onClick={handleClick("login")}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={LoginButtonImage}
            alt="login"
            height={46}
          />
        </a>
        <a
          href={registerUrl}
          onClick={handleClick("register")}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={RegisterButtonImage}
            alt="register"
            height={48}
          />
        </a>
      </Box>

      <Box sx={styles.images}>
        <img
          alt="hero background"
          src={HeroBackground}
          style={{
            animation: "herobg ease-out 1.2s infinite",
            left: 0,
            right: 0,
            margin: "0 auto",
            top: -10,
          }}
        />

        <img
          alt="hero midground"
          src={HeroMidground}
          style={{
            animation: "heromid ease-out 1.2s infinite",
            left: 0,
            right: 0,
            margin: "0 auto",
            top: 56,
          }}
        />

        <img
          alt="hero foreground"
          src={HeroForeground}
          style={{
            animation: "herofore ease-out 1.2s infinite",
            // left: 96,
            left: 0,
            right: 0,
            margin: "0 auto",
            top: 122,
          }}
        />
      </Box>

      <Box
        sx={{
          background: `linear-gradient(180deg, #2E005C 0%, #120125 100%)`,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          textAlign={"center"}
          paddingX={"16px"}
          spacing="12px"
          sx={{
            backgroundImage: `url(${BgSection})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "right",
            backgroundPositionY: "80%",
          }}
        >
          <Typography
            sx={joinSx(styles.hightlight, {
              fontSize: "20px",
              fontWeight: 800,
            })}
          >
            รวมความปัง แจกความรวยที่ GO989
          </Typography>

          <img
            alt="logo"
            src={Logo}
            style={{
              marginTop: "-2px",
              marginBottom: "-2px",
            }}
            width={230}
          />

          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              lineHeight: "1.3",
            }}
          >
            ✨ ยินดีต้อนรับสู่ GO989 สวรรค์สำหรับนักปั่น
            <br />
            เกมสล็อตที่น่าสนุกสุดปึ้งค่ะคุณน้าาาา! ✨
          </Typography>

          <Typography sx={styles.text}>
            คุณกำลังมองหาความตื่นเต้นและชนะเยอะ ๆ อยู่ใช่ไหม? อย่ามองไปไกล มาที่ GO989 เราคือ ที่หมายอันทรงพลัง
            สำหรับประสบการณ์การ เล่นสล็อต ที่ไม่เหมือนใคร GO989 ดาวน์โหลดแอปได้แล้วที่
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing="8px"
          >
            <img
              alt="download app store"
              src={BtnDownloadAppstore}
            />
            <img
              alt="download google play"
              src={BtnDownloadGoogleplay}
            />
          </Stack>

          <Typography sx={styles.text}>
            GO989 มีความหลากหลายของเกมสล็อต เต็มไปด้วยธีมที่ น่าสนใจ และคุณสมบัติโบนัสที่น่าตื่นเต้น
            ตั้งแต่เกมโปรดแบบคลาสสิค จนถึงการเปิดตัว ใหม่ GO989 มีสิ่งที่เหมาะกับผู้เล่นทุกคน GO989 แจกรางวัลแจ็คพอตใหญ่
            คุณจะเป็นผู้ชนะคนต่อไปของเราหรือไม่? GO989 ปลอดภัยและมั่นคง เล่นอย่างมั่นใจ เรารู้ว่าความรู้สึก
            ปลอดภัยและความมั่นคงของคุณคือ สิ่งสำคัญ สูงสุดของเรา GO989 ได้รับใบอนุญาตและมีการรักษามาตรฐานการรักษา
            ความปลอดภัยที่ ทันสมัย เพื่อให้ได้รับการเล่นเกม ที่ยุติธรรมและปลอดภัย ที่ GO989
            คุณจะเพลิดเพลินกับโบนัสต้อนรับ รวมถึงฟรีสปินและโปรโมชั่น ที่น่า ตื่นเต้น ที่ช่วยเพิ่มความสนุกและการชนะของคุณ
            GO989 เข้าถึงได้ทุกที่ ทุกเวลา สัมผัสความตื่นเต้นของ GO989 ทุกที่คุณไป! เว็บไซต์ GO989
            ของเราได้รับการปรับปรุงให้เหมาะสมสำหรับอุปกรณ์ เคลื่อนที่ทุกชนิด ช่วยให้คุณสามารถเพลิดเพลินกับ
            เกมสล็อตที่ชื่นชอบ ได้ทุกที่ ทุกเวลา ที่ GO989
          </Typography>

          <img
            alt="payments"
            src={Payments}
          />

          <Typography sx={styles.text}>
            ทีมงานบริการลูกค้าที่เป็นมิตรของเราพร้อมให้บริการตลอด 24 ชั่วโมง ติดต่อเราที่ GO989
            พร้อมที่จะเริ่มการผจญภัยในการเล่นสล็อต แล้วใช่ไหม? สมัครสมาชิกกับ GO989 วันนี้และเริ่มความสนุก ได้เลย!
            แล้วมาพบกับค่ายเกมชั้นนำมากมาย
          </Typography>
        </Stack>

        <Box
          sx={{
            marginY: "12px",
            background: "#E9A731",
            height: "1px",
            width: "100%",
          }}
        />

        <Box
          sx={{
            textAlign: "center",
            paddingBottom: "60px",
          }}
        >
          <img
            alt="providers"
            src={Providers}
          />
        </Box>
      </Box>
      <LineSupportButton />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "20px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
    color: "white",
  },
  images: {
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "360px",
    img: {
      position: "absolute",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginY: "22px",
    padding: "0px 20px",
    gap: "8px",

    a: {
      // width: "50%",
      cursor: "pointer",
    },
  },
  footer: {
    zIndex: 0,
    position: "fixed",
    bottom: "0",
    width: "100vw",
    height: "200px",
    flexShrink: 0,
  },
  hightlight: {
    background: "linear-gradient(180deg, #D37E00 1%, #FEDE79 35%, #D37E00 68.5%, #FDD65A 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  title: {
    fontSize: "40px",
    fontWeight: 800,
    textAlign: "center",
    lineHeight: "1.1",
  },
  title2: {
    fontSize: "22px",
    fontWeight: 600,
    textAlign: "center",
    lineHeight: "1.3",
  },
  text: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "1.3",
  },
});

export default LandingPage;
